import { graphql, useStaticQuery } from 'gatsby'

interface GQLTags {
  allMarkdownRemark: {
    group: Array<{
      fieldValue: string
      totalCount: number
    }>
  }
}

interface Tag {
  count: number
  value: string
}

export const useTags = (): Tag[] => {
  // NOTE: only query markdown files in /notes/
  const { allMarkdownRemark } = useStaticQuery<GQLTags>(graphql`
    {
      allMarkdownRemark(
        limit: 2000
        filter: { frontmatter: { published: { eq: true } } }
      ) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  return allMarkdownRemark.group
    .map((tags) => ({
      count: tags.totalCount,
      value: tags.fieldValue,
    }))
    .sort(({ count: prevCount }, { count: nextCount }) => {
      if (prevCount < nextCount) {
        return 1
      }

      if (prevCount > nextCount) {
        return -1
      }

      return 0
    })
}
