import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import type { FC } from 'react'
import { Fragment } from 'react'

import { Layout } from '../components/Layout'
import { SEO } from '../components/Seo'
import { tags as tagsRoute } from '../constants/routes'
import { useTags } from '../hooks/useTags'

const TagsPage: FC = () => {
  const tags = useTags()

  return (
    <Layout>
      <SEO keywords={['tags']} pathname={tagsRoute} title="All tags" />
      <h2>Tags</h2>
      {tags.map((tag, ii) => (
        <Fragment key={tag.value}>
          <Link to={`${tagsRoute}/${kebabCase(tag.value)}/`}>
            {tag.value} ({tag.count})
          </Link>
          {ii !== tags.length - 1 && ', '}
        </Fragment>
      ))}
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default TagsPage
